import React, { useEffect } from 'react';
import BaseLayoutTemplate from '../../components/environments/BaseLayoutTemplate';
import PageTitle from '../../components/atoms/PageTitle';
import Anchor from '../../components/atoms/Anchor';
import styled from 'styled-components';
import { breakpoint, mainColor } from '../../components/theme';
import HeadBase from '../../components/molecules/HeadBase';

type SubmittedPageProps = {
  location: {
    state: {
      fromPage?: 'contact';
    };
  };
};

const SubmittedPage: React.FC<SubmittedPageProps> = ({ location }) => {
  useEffect(() => {
    // うまくいかないのでやめる
    // if (location.state?.fromPage == null) {
    //   navigate('/');
    // }
  }, []);

  return (
    <BaseLayoutTemplate>
      <HeadBase noindex />

      {/* アイキャッチ */}
      <PageTitle title='Contact' span='お問い合わせ' />

      <MessageWrap>
        <p>お問い合わせは送信されました。</p>
        <p>担当の者よりご連絡差し上げますので今しばらくお待ち下さい。</p>
        <Alert>
          <h2>auやdocomoなどのキャリアメールをお使いの方へ</h2>
          <p>
            お客様の迷惑メール対策設定状況によっては、メールが届かない場合がございます。
            <br />
            恐れ入りますが、kouhou@croccha.jpの受信設定をお願いいたします。
          </p>
        </Alert>
        <StyledAnchor url='/'>Topページへ戻る</StyledAnchor>
      </MessageWrap>
    </BaseLayoutTemplate>
  );
};

export default SubmittedPage;

const MessageWrap = styled.div`
  padding: 56px 1rem 100px;
  text-align: center;
`;

const StyledAnchor = styled(Anchor)`
  margin-top: 146px;
  text-decoration: underline;
  color: ${mainColor};
`;

const Alert = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  background: #fff2f1;
  padding: 2rem 1rem;
  border-radius: 4px;
  > h2 {
    margin-bottom: 1rem;
    font-size: 18px;
  }
`;
